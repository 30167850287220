<template>
    <div style="min-height: 500px;">
        <CForm ref="form" class="needs-validation">
            <CRow class="mb-3" v-if="!data.id">
                <CCol col="6">
                    <CSelect
                        label="Rapor Tipi"
                        type="text"
                        horizontal
                        placeholder="Seçiniz"
                        :options="reportTypes"
                        :value.sync="data.reportType"
                        required
                        @change="changeReportType"
                    />
                </CCol>
                <CCol col="6" v-if="data.reportType == 'general'">
                    <Multiselect 
                        v-model="data.country"
                        :options="countries" 
                        selected-label="Seçildi"
                        deselect-label="Kaldır"
                        placeholder="Rapor Ülkesi" 
                        track-by="value" 
                        label="label"
                        @input="changeReportType"
                    >
                        <span slot="noResult">Dikkat! Aramanıza uygun kayıt bulunamadı.</span>
                    </Multiselect>
                </CCol>
                <CCol col="6">
                    <Multiselect 
                        v-model="data.adcampType"
                        :options="adcampTypes" 
                        :multiple="true" 
                        selected-label="Seçildi"
                        deselect-label="Kaldır"
                        :close-on-select="false" 
                        placeholder="Kampanya Tipi" 
                       
                    >
                        <span slot="noResult">Dikkat! Aramanıza uygun kayıt bulunamadı.</span>
                    </Multiselect>
                   
                </CCol>
            </CRow>
            <CRow>
                <CCol col="6">
                    <CInput
                        label="Başlangıç Tarihi"
                        horizontal
                        type="date"
                        v-model="data.startDate"
                        required
                    />
                </CCol>
                <CCol col="6">
                    <CInput
                        label="Bitiş Tarihi"
                        horizontal
                        type="date"
                        v-model="data.endDate"
                        required
                    />
                </CCol>
            </CRow>
        </CForm>
        <div v-if="reports.length > 0" >
            <div v-if="data.reportType == 'graphic'" ref="graphic">
                <CChartLine
                    class="mt-3 mx-3"
                    :labels="graphicLabels"
                    :datasets="graphicData"
                    label="Adet"
                    :options="{
                        maintainAspectRatio: true,
                        tooltips: {
                            custom: (context) => customTooltip(context)
                        }
                    }"
                />
            </div>
            <CDataTable
                v-else
                :items="reports"
                :fields="fields"
                :items-per-page="10"
                hover
                pagination
                :loading="false"
                class="modalTable"
                v-on:refresh="refresh"
            >
                <template #cartCheckoutRatio="{item}">
                    <td> {{ item.cartCheckoutRatio != '' &&  '%' + item.cartCheckoutRatio}} </td>
                </template>
                <template #updateTime="{item}">
                    <td> {{ item.updateTime && dateFormat(item.updateTime) }} </td>
                </template>
            </CDataTable>
        </div>

    </div>
     
</template>

<script>

    import Multiselect from 'vue-multiselect'
    import moment from 'moment';
    import {CChartLine} from '@coreui/vue-chartjs'


    export default{
        name: "adCamReportForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        components:{
            Multiselect, CChartLine
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {...this.params}

            },
        },

        computed:{
            reports: function(){
                return this.$store.getters.adCampReportData
            },
            adcampTypes: function(){
                return this.$store.getters.adcampTypes
            },
            graphicData: function(){
                let data = [];
                let types = [...new Set(this.$store.getters.adCampReportData.map(item => item.name))];
                let days = [...new Set(this.$store.getters.adCampReportData.map( r => r.updateTime).sort())];
                
                types.map( (t) => {

                    let datas = this.$store.getters.adCampReportData.filter(item => item.name == t)
                    let color = Math.floor(Math.random()*16777215).toString(16);
                    let typeData =  days.map( day => {
                        let sumRevenue = 0;
                        datas.filter( d => d.updateTime == day).map( item => 
                            sumRevenue += parseInt(item.trRevenue.replace(',','')) + parseInt(item.intRevenue.replace(',',''))
                        )
                        return sumRevenue;
                    })

                    data.push({
                        label: t,
                        backgroundColor: 'rgba(220, 220, 220, 0.2)',
                        borderColor: '#'+color,
                        pointBackgroundColor: '#'+color,
                        data: typeData,
                    })
                })
                return data;
            },
            graphicLabels: function(){
                let days = [...new Set(this.$store.getters.adCampReportData.map( r => r.updateTime).sort())];
                return days
            },
            countries() {
                let data = [{label: "Tümü", value: null}]
                this.$store.getters.allCountries.map(c => data.push({label: c.name, value: c.id}))
                return data
            },
        },

        data: function(){
            return{
                data: this.params ? {...this.params} : {},
                fields: [
                    {key: 'name', label: 'Kampanya Adı', filter: false},
                    {key: 'updateTime', label: 'Tarih', filter: false},
                    {key: 'numCarts', label: 'Sepet Sayısı', filter: false},
                    {key: 'numCheckouts', label: 'Checkout Sayısı', filter: false},
                    {key: 'cartCheckoutRatio', label: 'Checkout Oranı', filter: false},
                    {key: 'trRevenue', label: 'Türkiye Ciro', filter: false},
                    {key: 'intRevenue', label: 'Yurt Dışı Ciro', filter: false}
                ],
                reportTypes: [
                    {label: "Genel Kampanya Raporu", value: "general"},
                    {label: "Gün Bazlı Grafik Raporu", value: "graphic"},
                    {label: "Gün Bazlı Tablo Raporu", value: "table"},
                ]
            }
        },

        methods: {
            refresh: async function(){

                let formData = new FormData();
                formData.append('startTime', this.data.startDate);
                formData.append('endTime', this.data.endDate);
                formData.append('reportType', this.data.reportType);

                if(this.data.id)
                formData.append('acid', this.data.id);

                if(this.data.adcampType)
                formData.append('acType', this.data.adcampType);

                if(this.data.country && this.data.country !== '' && this.data.country.value !== null)
                formData.append('countryId', this.data.country.value);
                
                await this.$store.dispatch('marketingAdCamp_report', formData)
            },

            dateFormat: function(data){
                return moment(data).format('DD.MM.YYYY dddd')
            },

            additionalEvent: async function(){

                let data = {
                    responseType: 'blob',
                    startTime: this.data.startDate,
                    endTime: this.data.endDate,
                    reportType: this.data.reportType,
                }

                if(this.data.id)
                    data.acid = this.data.id

                if(this.data.adcampType)
                    data.acType = this.data.adcampType.toString()

                if(this.data.country && this.data.country !== '' && this.data.country.value !== null)
                    data.countryId = this.data.country.value;
                
                await this.$store.dispatch('marketingAdCamp_excelReport', data)

                if(this.$store.getters.marketingStatus) {
                    const url = window.URL.createObjectURL(new Blob([this.$store.getters.marketingStatus]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'AdCampExcel_' + moment(new Date()).format('DD/MM/YYYY') + '.xls');
                    document.body.appendChild(link);
                    link.click();
                }
            },

            customTooltip: function(context){

                if(context.body){
                    let reportData = this.reports.filter( r => 
                        r.updateTime == context.dataPoints[0].xLabel && parseInt(r.trRevenue.replace(',','')) + parseInt(r.intRevenue.replace(',','')) == context.dataPoints[0].value
                    )

                    context.body = []
                    reportData.map( r => {
                        context.body.push({ lines: [ r.name,
                            'Checkout Sayısı: '+ r.numCheckouts,
                            'Sepet Sayısı: ' + r.numCarts ,
                            'Checkout Oranı: '+ r.cartCheckoutRatio, 
                            'Türkiye Ciro: ' + r.trRevenue,
                            'Yurtdışı Ciro: '+ r.intRevenue 
                        ]
                        })
                    })

                    context.height = (reportData.length * 5 * 15) + 20
                    context.width = 250;
                    
                }
            },

            changeReportType: async function(){
                this.$store.commit('adCampReportData', [])
                
                if(this.data.reportType !== 'general'){
                    this.fields = [
                        {key: 'name', label: 'Kampanya Adı', filter: false},
                        {key: 'updateTime', label: 'Tarih', filter: false},
                        {key: 'numCarts', label: 'Sepet Sayısı', filter: false},
                        {key: 'numCheckouts', label: 'Checkout Sayısı', filter: false},
                        {key: 'cartCheckoutRatio', label: 'Checkout Oranı', filter: false},
                        {key: 'trRevenue', label: 'Türkiye Ciro', filter: false},
                        {key: 'intRevenue', label: 'Yurt Dışı Ciro', filter: false}
                    ];
                }else {
                    if(this.data.country && this.data.country !== '' && this.data.country.value !== null){

                        this.fields = [
                            {key: 'name', label: 'Kampanya Adı', filter: false},
                            {key: 'updateTime', label: 'Tarih', filter: false},
                            {key: 'numCarts', label: 'Sepet Sayısı', filter: false},
                            {key: 'numCheckouts', label: 'Checkout Sayısı', filter: false},
                            {key: 'cartCheckoutRatio', label: 'Checkout Oranı', filter: false},
                            {key: 'revenue', label: 'Ciro', filter: false},
                        ];
                    } else {
                        this.fields = [
                            {key: 'name', label: 'Kampanya Adı', filter: false},
                            {key: 'numCarts', label: 'Sepet Sayısı', filter: false},
                            {key: 'numCheckouts', label: 'Checkout Sayısı', filter: false},
                            {key: 'cartCheckoutRatio', label: 'Checkout Oranı', filter: false},
                            {key: 'trRevenue', label: 'Türkiye Ciro', filter: false},
                            {key: 'intRevenue', label: 'Yurt Dışı Ciro', filter: false}
                        ];
                    }
                }
            }
        }
    }
</script>